/* this file is transformed by vux-loader */
/* eslint-disable */
import _toConsumableArray from "F:/zhaoyanning/\u9ED1\u9F99\u6C5F\u533B\u79D1\u5927\u5B66\u9644\u5C5E\u533B\u9662/mobile-v2/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import EdMonth from '@/components/EdMonth/Index';
import Scroll from '@/components/Scroll/Scroll';
import Cell from 'vux/src/components/cell';
import Group from 'vux/src/components/group';
import ViewBox from 'vux/src/components/view-box';
export default {
  name: 'MealAppraiseList',
  components: {
    ViewBox: ViewBox,
    Group: Group,
    Cell: Cell,
    Scroll: Scroll,
    EdMonth: EdMonth
  },
  data: function data() {
    return {
      infoList: [],
      pullDownRefresh: {
        threshold: 40,
        // 下拉多少开始刷新
        stop: 20 // 下拉回弹到多少停住

      },
      pullUpLoad: {
        threshold: 25
      },
      isPullDown: false,
      // 正在下拉刷新
      isPullUp: false,
      // 正在上拉加载
      pageIndex: 1,
      pageSize: 20,
      totalPage: 0,
      emptyShow: false,
      search: '',
      currDate: '',
      startDate: '',
      endDate: ''
    };
  },
  computed: {},
  methods: {
    onPullingDown: function onPullingDown() {
      if (this.isPullDown) return;
      this.isPullDown = true; // 下拉刷新

      this.infoList = [];
      this.pageIndex = 1;
      this.getInfoList();
    },
    onPullingUp: function onPullingUp() {
      this.pageIndex = this.pageIndex + 1;

      if (this.pageIndex > this.totalPage) {
        this.updateScroll();
        return;
      }

      if (this.isPullUp) return;
      this.isPullUp = true;
      this.getInfoList();
    },
    updateScroll: function updateScroll(flag) {
      this.isPullDown = false;
      this.isPullUp = false;
      this.$refs.scroll.forceUpdate(flag);
    },
    clearSearch: function clearSearch() {
      this.search = '';
      this.getInfoList();
    },
    monthChange: function monthChange(startDate, endDate) {
      this.startDate = this.$utils.DateUtils.formatDate(startDate, 'YYYYMMDD');
      this.endDate = this.$utils.DateUtils.formatDate(endDate, 'YYYYMMDD');
      this.pageIndex = 1;
      this.getInfoList();
    },
    getSysDate: function getSysDate() {
      var _this = this;

      this.$api.Sys.getSystemDate().then(function (response) {
        var code = response.code,
            msg = response.msg,
            data = response.data;

        if (+code !== _this.$code.success) {
          _this.$utils.Tools.toastShow(msg);
        } else {
          _this.currDate = data.sysDate;
          _this.startDate = _this.$utils.DateUtils.getMonthStart(data.sysDate, 'YYYYMMDD');
          _this.endDate = _this.$utils.DateUtils.getMonthEnd(data.sysDate, 'YYYYMMDD');

          _this.getInfoList();
        }
      }).catch(function () {
        _this.$utils.Tools.httpError();
      });
    },
    getInfoList: function getInfoList() {
      var _this2 = this;

      this.$utils.Tools.loadShow();
      this.emptyShow = false;
      var params = {
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        search: {
          startDate: this.startDate,
          endDate: this.endDate,
          userSerial: this.$utils.Store.getItem('userSerial')
        }
      };
      this.$api.MealAppraise.selectListPageByEntity(params).then(function (response) {
        _this2.infoList = [];

        _this2.$utils.Tools.loadHide();

        var code = response.code,
            msg = response.msg,
            data = response.data;

        if (+code !== _this2.$code.success) {
          _this2.$utils.Tools.toastShow(msg);

          _this2.updateScroll(false);
        } else {
          var _this2$infoList;

          (_this2$infoList = _this2.infoList).push.apply(_this2$infoList, _toConsumableArray(data.records));

          _this2.pageIndex = data.pageIndex;
          _this2.totalPage = data.totalPage;

          if (_this2.infoList.length === 0) {
            _this2.emptyShow = true;
          }

          _this2.updateScroll(false);
        }
      }).catch(function () {
        _this2.$utils.Tools.httpError();
      });
    },
    recordClick: function recordClick(value, operation) {
      this.$router.push({
        path: '/MealAppraiseForm',
        query: {
          xh: value,
          operation: operation
        }
      });
    },
    onItemClick: function onItemClick() {
      this.infoList = [];
      this.getInfoList();
    }
  },
  created: function created() {
    this.getSysDate();
  },
  mounted: function mounted() {},
  destroyed: function destroyed() {}
};