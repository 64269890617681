/* this file is transformed by vux-loader */
/* eslint-disable */
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'EdMonth',
  props: {
    currDate: String
  },
  data: function data() {
    return {
      dateValue: '',
      year: '',
      month: ''
    };
  },
  methods: {
    showPopup: function showPopup() {
      var _this = this; // 需要注意 onCancel 和 onConfirm 的 this 指向


      this.$vux.datetime.show({
        cancelText: '取消',
        confirmText: '确定',
        format: 'YYYY-MM',
        value: this.dateValue,
        onConfirm: function onConfirm(val) {
          _this.dateValue = val;
          _this.year = _this.$utils.DateUtils.getYear(val);
          _this.month = _this.$utils.DateUtils.getMonth(val);
          _this.startDate = _this.$utils.DateUtils.getMonthStart(val);
          _this.endDate = _this.$utils.DateUtils.getMonthEnd(val);

          _this.$emit('month-change', _this.startDate, _this.endDate);
        }
      });
    }
  },
  watch: {
    currDate: function currDate(val) {
      this.dateValue = val;
      this.year = this.$utils.DateUtils.getYear(val);
      this.month = this.$utils.DateUtils.getMonth(val);
    }
  },
  created: function created() {}
};